import { default as Dayjs, default as dayjs } from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { FirebaseAuthContext } from '../SignIn';
import { StatusBadge } from '../Tradelog/Tradelog';
import { TradelogContext } from '../Tradelog/context';
import { callApi } from '../util';
import CandleChart from './CandleChart';
import PriceChart from './PriceChart';
// Get the current time in Unix format
const now = dayjs().unix();

export function ForexPair({ pair }) {
    return (
        <span>
            <span className={`fx fx-${pair.slice(0, 3)}`}>{pair.slice(0, 3)}</span>
            <span className={`fx fx-${pair.slice(3)}`}>{pair.slice(3)}</span>
        </span>
    );
}

const signals = (type, asset, minZScore) => (
    <div className="d-flex align-items-center">
        <span style={{ width: "3rem" }}>{type}</span>
        <span style={{ width: "4rem" }} className={Math.abs(asset[`z_score_${type}`]) < 2 ? "opacity-70" : Math.abs(asset[`z_score_${type}`]) > 3 ? "text-warning fw-bold" : ""}>
            {asset[`z_score_${type}`].toFixed(2)}
        </span>
        <span>{asset.z_scores_high_50.map((i, index) => {
            const high = asset["z_scores_high_" + type][index]
            const low = Math.abs(asset["z_scores_low_" + type][index])
            if (high >= 3 || low >= 3) {
                return '📀'
            }
            if (high >= minZScore) {
                return '🔴'
            }
            if (low >= minZScore) {
                return '🟢'
            }
            return '⚪'
        })}</span>
    </div>
);

export default function Screener() {
    const user = useContext(FirebaseAuthContext); // Access the token
    const api = useContext(TradelogContext);
    const [minZScore, setMinZScore] = useState(2.4);
    const [minIndexScore, setMinIndexScore] = useState(55);
    const [screenerData, setScreener] = useState([]);
    const navigate = useNavigate();
    const [copySuccess, setCopySuccess] = useState({ color: "primary", text: 'Screener' });

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopySuccess({ color: "success", text: 'Copied!' });
            await callApi(user, "POST", `/capital_com/refresh-watchlist`);
            // * open tradingview in new tab
            // window.open(`https://www.tradingview.com/chart/YiD4OsYt/?interval=720`, '_blank');
        } catch (err) {
            setCopySuccess({ color: "danger", text: 'Failed to copy!' });
        }
    };

    useEffect(() => {
        setCopySuccess({ color: "primary", text: 'Screener' });
    }, [minIndexScore]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await callApi(user, "GET", `/capital_com/screener`);
                setScreener(data);
                setCopySuccess({ color: "primary", text: 'Screener' });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 5 * 60 * 1000); // Refresh every 5 minutes

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [user]);

    const trades = useMemo(() => api.allTrades.sort((a, b) => b.open_unix - a.open_unix).map(i => ({
        ...i,
        isOlderThan3Weeks: now - i.open_unix > 3 * 7 * 24 * 60 * 60,
        isOlderThan6Weeks: now - i.open_unix > 6 * 7 * 24 * 60 * 60,
        isOlderThen12Weeks: now - i.open_unix > 12 * 7 * 24 * 60 * 60,
        date: dayjs.unix(i.open_unix).format("D MMM"),
        close_date: i.close_unix ? dayjs.unix(i.close_unix).format("D MMM") : null,
    })).map(i => ({ ...i, className: i.isOlderThan6Weeks ? "opacity-40" : (i.isOlderThan3Weeks ? "opacity-70" : "") })), [api.allTrades]);

    const preparedData = useMemo(() => screenerData.sort((a, b) => b.index_score - a.index_score).map(i => ({
        ...i,
        trades: trades.filter(j => j.pair === i.epic).slice(0, 4),
        correlatedTrades: trades.filter(j =>
            i.type === "forex" &&
            ["waiting", "unfilled", "filled"].includes(j.status) &&
            j.pair !== i.epic && (
                [i.epic.slice(0, 3), i.epic.slice(-3)].includes(j.pair.slice(0, 3)) ||
                [i.epic.slice(0, 3), i.epic.slice(-3)].includes(j.pair.slice(-3))
            )),
        // Calculate Z-scores for recent highs
        z_scores_high_50: i.recent_prices.slice(-12).map(ohlc => (ohlc[1] - i.sma_50) / i.std_dev_50),
        z_scores_high_100: i.recent_prices.slice(-12).map(ohlc => (ohlc[1] - i.sma_100) / i.std_dev_100),
        z_scores_high_200: i.recent_prices.slice(-12).map(ohlc => (ohlc[1] - i.sma_200) / i.std_dev_200),
        z_scores_high_360: i.recent_prices.slice(-12).map(ohlc => (ohlc[1] - i.sma_360) / i.std_dev_360),

        // Calculate Z-scores for recent closes (only last 12 candles)
        z_scores_low_50: i.recent_prices.slice(-12).map(ohlc => (ohlc[2] - i.sma_50) / i.std_dev_50),
        z_scores_low_100: i.recent_prices.slice(-12).map(ohlc => (ohlc[2] - i.sma_100) / i.std_dev_100),
        z_scores_low_200: i.recent_prices.slice(-12).map(ohlc => (ohlc[2] - i.sma_200) / i.std_dev_200),
        z_scores_low_360: i.recent_prices.slice(-12).map(ohlc => (ohlc[2] - i.sma_360) / i.std_dev_360),
    })),
        [screenerData, trades.length]);

    const data = preparedData.filter(i => i.index_score >= minIndexScore)

    // console.log("Filter data", data)

    const semiOldTrades = trades.filter(i => !i.isOlderThan12Weeks);
    const watchlistString = [
        "###Open Trades",
        ...trades.filter(i => i.status === "filled").map(trade => `${trade.exchange}:${trade.pair}`),
        "###Waiting for setup",
        ...trades.filter(i => i.status === "waiting" || i.status === "unfilled").map(trade => `${trade.exchange}:${trade.pair}`),
        "###Signals " + Dayjs().format('ddd D MMM - HH:mm'),
        ...data.map(i => i.symbol),
        "###Profitable Trades",
        ...semiOldTrades.filter(i => i.result > 2).map(trade => `${trade.exchange}:${trade.pair}`),
        "###Losing Trades",
        ...semiOldTrades.filter(i => i.result < -2).map(trade => `${trade.exchange}:${trade.pair}`),
        "###Break-Even Trades",
        ...semiOldTrades.filter(i => i.result >= -2 && i.result <= 2).map(trade => `${trade.exchange}:${trade.pair}`),
        "###Cancelled Trades",
        ...semiOldTrades.filter(i => i.status === "cancelled").map(trade => `${trade.exchange}:${trade.pair}`),
    ].join(",");

    return (
        <div>
            <Container className="py-5 px-3">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h3 onClick={() => copyToClipboard(watchlistString)} className="text-white py-3">{copySuccess.text}</h3>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="index_score" className="text-white">
                                Min Index Score <b><span className="text-info">{Number(minIndexScore).toFixed(1)}</span></b>
                            </Label>
                            <Input
                                id="index_score"
                                name="range"
                                type="range"
                                value={minIndexScore}
                                min={50}
                                max={120}
                                step={2.5}
                                onChange={(e) => setMinIndexScore(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="zscorerange" className="text-white">
                                Min Z-score <b><span className="text-info">{Number(minZScore).toFixed(1)}</span></b>
                            </Label>
                            <Input
                                id="zscorerange"
                                name="range"
                                type="range"
                                value={minZScore}
                                min={0}
                                max={3.1}
                                step={0.1}
                                onChange={(e) => setMinZScore(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {data.map((asset, index) => <Row key={index} className="py-4 border-bottom mb-3">
                    <Col md={6} lg={4} xl={3} className="text-white">
                        <div className="">
                            <a href={`https://www.tradingview.com/chart/YiD4OsYt/?symbol=${asset.symbol}&interval=720`} target="_blank" className="no-link h4" rel="noreferrer">
                                {asset.type === "forex" ? <span>{asset.symbol.split(":")[0]}:<ForexPair pair={asset.epic} /></span> : asset.symbol}
                            </a>
                        </div>
                        <div className="pt-3">
                            <div className="h3 pb-2">
                                <span className="badge rounded-pill bg-info text-dark">{asset.index_score.toFixed(1)}%</span>
                            </div>
                            <div>{signals("360", asset, minZScore)}</div>
                            <div className="opacity-70">{signals("200", asset, minZScore)}</div>
                            <div className="opacity-40">{signals("100", asset, minZScore)}</div>
                            <div className="opacity-40">{signals("50", asset, minZScore)}</div>
                        </div>

                        <div className="pt-3">
                            {asset.trades.length > 0 && <div className="pt-3 border-top mt-3">
                                <div className="h6">Open trades</div>
                                {asset.trades.map(t => (
                                    <a href="#" key={t.trade_id} className={`me-1 ${t.className}`} onClick={() => navigate(`/log/trade/${t.trade_id}`)}>
                                        <StatusBadge status={t.status} date={t.date} close_date={t.close_date} />
                                    </a>
                                ))}
                            </div>}
                        </div>
                        {asset.correlatedTrades.length > 0 && asset.type === "forex" && <div className="border-top pt-3 mt-3">
                            <div className="h6">Correlated trades</div>
                            {asset.correlatedTrades.map(t => (<div key={t.trade_id} className="pt-3">
                                <a href={`https://www.tradingview.com/chart/YiD4OsYt/?symbol=${asset.symbol}&interval=720`} target="_blank" className="no-link" rel="noreferrer">
                                    <ForexPair pair={t.pair} />
                                </a> <a href="#" className={`me-1 ${t.className}`} onClick={() => navigate(`/log/trade/${t.trade_id}`)}><StatusBadge status={t.status} date={t.date} close_date={t.close_date} /></a>
                            </div>))}
                        </div>}

                        {asset['1_hour_50_candles'] && <div className="pt-3 mt-3 border-top" >
                            <div >
                                <CandleChart candleData={asset['1_hour_50_candles']} />
                            </div>
                        </div>}
                    </Col>
                    <Col md={6} lg={8} xl={9}>
                        {asset['4_hour_closes'] && <div className="pt-3 pt-0-md " >
                            <PriceChart prices={asset['4_hour_closes']} sma={asset['4_hour_200_sma']} />
                        </div>}

                    </Col>
                    <Col xs={12} className="text-white opacity-70">
                        <div className="pt-3 pt-0-md" ><small>{asset.timestamp}</small></div>
                    </Col>
                </Row>)}
            </Container>
        </div>
    );
}
