import { faExpandWide, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Badge, Button, Col, Container, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ForexPair } from '../Screener/Screener';
import { STATUS, STATUS_FILTER, STRATEGIES, TradelogContext, VIEWS } from './context';
import DateRangeSlider from './DateRangeSlider';
import OpenTradesChart from './OpenTradesChart';
import SunburstChart from './Sunburts';
import TradeDurationChart from './TradeDurationChart';
import TradeSwiper from './TradeSwiper';
import WaterfallChart from './Waterfall';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
export default function TradeLogindex() {
    const api = useContext(TradelogContext);
    const params = useParams();

    if (params.trade_id) {
        return <>
            <TradeSwiper />
            <CreateCommentModal />
            <UpdateCommmentModal />
        </>
    }

    return <div>
        <Container className="pt-5" fluid={api.fullscreen}>
            <div className="d-flex">
                <h3 className="text-white">Trades</h3>
                <div className="ms-auto">
                    {/* {api.fullscreen && <Button className="me-2" size="sm" color="dark" onClick={() => api.setFullscreen(false)}><FontAwesomeIcon icon={faCompressWide} /></Button>} */}
                    {/* {!api.fullscreen && <Button className="me-2" size="sm" color="dark" onClick={() => api.setFullscreen(true)}><FontAwesomeIcon icon={faExpandWide} /> </Button>} */}
                    {/* {api.view === VIEWS.SHOW_ALL && <Button className="me-2" size="sm" color="dark" onClick={() => api.setView(VIEWS.SHOW_PREVIEW)}><FontAwesomeIcon icon={faCaretUp} /></Button>} */}
                    {/* { api.view === VIEWS.SHOW_FIRST_AND_LAST && <Button className="me-2" size="sm" color="dark" onClick={() => api.setView(VIEWS.SHOW_PREVIEW)}><FontAwesomeIcon icon={faList} /></Button>} */}
                    {/* {api.view === VIEWS.SHOW_PREVIEW && <Button className="me-2" size="sm" color="dark" onClick={() => api.setView(VIEWS.SHOW_ALL)}><FontAwesomeIcon icon={faCaretDown} /></Button>} */}
                    <Button size="sm" color="success" onClick={() => api.setCreateModal(true)}><FontAwesomeIcon icon={faPlus} /> Add trade</Button>
                </div>
            </div>
            <Row>
                <Col md="12" lg="6">
                   <DateRangeSlider />
                </Col>

                <Col md="6" lg="2">
                    <FormGroup>
                        <Label className="text-white" >Pair</Label>
                        <div className="text-black">
                            <Select
                                options={api.pairs.map(i => ({ value: i, label: i }))}
                                value={api.filter.pair ? ({ value: api.filter.pair, label: api.filter.pair }) : null}
                                onChange={(value) => api.setFilter(prev => ({ ...prev, pair: value?.value || null }))}
                                placeholder="Pair"
                                isClearable
                            />
                        </div>
                    </FormGroup>

                </Col>
                <Col md="6" lg="2">
                    <FormGroup>
                        <Label className="text-white" >Status</Label>
                        <div className="text-black">
                            <Select
                                options={STATUS_FILTER}
                                value={STATUS_FILTER.find(i => i.value === api.filter.status)}
                                onChange={(value) => api.setFilter(prev => ({ ...prev, status: value?.value || null }))}
                                placeholder="Status"
                                isClearable
                            />
                        </div>
                    </FormGroup>

                </Col>
                <Col md="6" lg="2">
                    <FormGroup>
                        <Label className="text-white" >Strategy</Label>
                        <div className="text-black">
                            <Select
                                options={STRATEGIES}
                                value={STRATEGIES.find(i => i.value === api.filter.strategy)}
                                onChange={(value) => api.setFilter(prev => ({ ...prev, strategy: value?.value || null }))}
                                placeholder="Strategy"
                                isClearable
                            />
                        </div>
                    </FormGroup>

                </Col>
            </Row>
        </Container>
        <Graphs />
        {api.filteredTrades.map((i, index) => <TradeItem key={index} trade={i} />)}
        <CreateModal />
        <CreateCommentModal />
        <UpdateCommmentModal />
    </div >
}

export const url = (trade) => `https://www.tradingview.com/chart/YiD4OsYt/?symbol=${trade.exchange}:${trade.pair}&interval=720`


function TradeItem({ trade }) {
    const api = useContext(TradelogContext);
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        if (api.view === VIEWS.SHOW_PREVIEW) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }, [api.view])

    return <Container fluid={api.fullscreen} className="py-5 border-bottom">
        <Row>
            <Col>
                <div >
                    <div >
                        <Row>
                            <Col md="6">
                                <h4>
                                    <a href={url(trade)} target="_blank" rel="noreferrer" className="no-link">
                                        {trade.exchange}: <ForexPair pair={trade.pair} />
                                    </a>  <StatusBadge status={trade.status} />  <ResultBadge result={trade.result} />
                                </h4>
                                <div className="fw-bold opacity-70 text-white">{trade.open_time} {trade.open_date}</div>
                            </Col>
                            <Col md="6" className="text-end">
                                <div className="d-flex">

                                    <Button className="ms-auto" color="danger" size="sm" outline onClick={() => api.deleteTrade(trade.trade_id)} ><FontAwesomeIcon icon={faTrash} /> Delete</Button>
                                    {/* {!isOpen && <Button className="ms-3" color="dark" size="sm" onClick={() => setIsOpen(true)} ><FontAwesomeIcon icon={faCaretDown} /></Button>}
                                    {isOpen && <Button className="ms-3" color="dark" size="sm" onClick={() => setIsOpen(false)} ><FontAwesomeIcon icon={faCaretUp} /></Button>} */}
                                    <Button className="ms-3" color="success" size="sm" outline onClick={() => navigate(`/log/trade/${trade.trade_id}`)} ><FontAwesomeIcon icon={faExpandWide} /> Review</Button>


                                    {/* <Button className="ms-3" color="success" size="sm" outline onClick={() => api.setUpdateModal(trade)} >Edit</Button> */}
                                    <Button className="ms-3" color="success" size="sm" outline onClick={() => api.setCreateCommentModal(trade)} ><FontAwesomeIcon icon={faPlus} /> Comment</Button>
                                </div>
                            </Col>
                        </Row>
                        {!isOpen && <Row className="pt-3 pointer" onClick={() => navigate(`/log/trade/${trade.trade_id}`)}>
                            <Col md="6" className="pb-3 pb-md-0" >
                                {trade.snapshot_img_src && <SnapshotImg src={trade.snapshot_img_src} />}
                                {(trade.message) && <p className="mb-0 pt-2 text-white">{trade.message}</p>}
                            </Col>
                            <Col md="6">
                                {trade.last_snapshot_img_src && <SnapshotImg src={trade.last_snapshot_img_src} />}
                                {(trade.last_message) && <p className="mb-0 pt-2 text-white">{trade.last_message}</p>}
                            </Col>
                        </Row>}
                    </div>
                    {/* {isOpen && <div>
                        {api.comments.filter(i => i.trade_id === trade.trade_id).map((i, index) => <div key={index} className="p-4 mt-4 trade-comment">
                            <div className="d-flex">
                                <div>
                                    <span className="fw-bold">{i.time} {i.date}</span> <em>+{hourDiff(trade.open_unix, i.unix)}</em>
                                </div>
                                <div className="ms-auto">
                                    <Button className="ms-2" size="sm" color="danger" outline onClick={() => api.deleteComment(i.trade_id, i.comment_id)} ><FontAwesomeIcon icon={faTrash} /></Button>
                                    <Button className="ms-2" size="sm" color="success" outline onClick={() => api.setUpdateCommentModal(i)} >Edit</Button>
                                </div>
                            </div>
                            <div>
                                {i.snapshot_img_src && <div className="pt-2"><img className="w-100" alt="Snapshot" src={i.snapshot_img_src} /></div>}
                                {(i.status || i.result) && <div className="pt-2">
                                    {i.status && <StatusBadge status={i.status} size="lg" />}
                                    {i.result && <ResultBadge result={i.result} size="lg" />}
                                </div>}
                                {(i.message) && <p className="mb-0 pt-2 text-white">{i.message}</p>}
                            </div>
                        </div>)}

                        <div>
                        </div>
                    </div>} */}
                </div>
                {/* {isOpen &&
                    <div className="pt-5 d-flex">
                        <Button className="ms-auto me-2" color="dark" size="sm" onClick={() => setIsOpen(false)} ><FontAwesomeIcon icon={faCaretUp} /></Button>
                        <Button color="success" size="sm" outline onClick={() => api.setCreateCommentModal(trade)} >Comment</Button>
                    </div>
                } */}
            </Col>
        </Row>
    </Container>

}

export const StatusBadge = ({ status, size = "lg", date, close_date }) => {
    const obj = STATUS.find(x => x.value === status)

    const appendDate = () => {
        if(date && close_date) {
            return ` ${date} - ${close_date}`
        }
        if(date){
            return ` ${date}`
        }
        return ""
    }
    return <Badge size={size} color={obj?.color}>{obj?.label}{appendDate()}</Badge>
}

export const ResultBadge = ({ result, size }) => {
    if (!result) {
        return null
    }
    const treshold = 1.5
    const color = result > treshold ? "success" : result < -1 * treshold ? "danger" : "dark";
    return <Badge pill size={size} color={color} >{result}%</Badge>

}

const defaultTrade = {
    snapshot_url: "",
    message: "",
    status: "filled", // win / loss / break-even
    strategy: "mean-reversion"
}
function CreateModal() {
    const api = useContext(TradelogContext);

    const [query, setQuery] = useState(defaultTrade)

    const onSubmit = () => {
        api.createTrade(query);
        setQuery(defaultTrade);
        api.setCreateModal(false);
    }

    return (
        <Modal size="lg" isOpen={api.createModal} toggle={() => api.setCreateModal(false)}>
            <ModalHeader toggle={() => api.setCreateModal(false)}>Create trade</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Snapshot url</Label>
                    <Input type="text" value={query.snapshot_url} onChange={(e) => setQuery(prev => ({ ...prev, snapshot_url: e.target.value }))} />
                </FormGroup>
                <FormGroup>
                    <Label>Message</Label>
                    <Input type="text" value={query.message} onChange={(e) => setQuery(prev => ({ ...prev, message: e.target.value }))} />
                </FormGroup>
                <FormGroup>
                    <Label >Status</Label>
                    <div className="text-black">
                        <Select
                            options={STATUS.slice(0, 2)}
                            value={STATUS.find(i => i.value === query.status)}
                            onChange={(value) => setQuery(prev => ({ ...prev, status: value.value }))}
                            placeholder="Status"
                        />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label >Strategy</Label>
                    <div className="text-black">
                        <Select
                            options={STRATEGIES}
                            value={STRATEGIES.find(i => i.value === query.strategy)}
                            onChange={(value) => setQuery(prev => ({ ...prev, strategy: value.value }))}
                            placeholder="Strategy"
                        />
                    </div>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="success" disabled={api.isLoading} onClick={onSubmit}>Submit</Button>
                <Button color="light" disabled={api.isLoading} onClick={() => api.setCreateModal(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

const defaultComment = {
    snapshot_url: "",
    message: "",
    status: null,
    result: null
}
function CreateCommentModal() {
    const api = useContext(TradelogContext);
    const trade = api.createCommentModal;
    const [query, setQuery] = useState(defaultComment);

    const onSubmit = () => {
        api.createComment(api.createCommentModal, query);
        setQuery(defaultComment);
        api.setCreateCommentModal(false);
    }

    return (
        <Modal size="lg" isOpen={api.createCommentModal ? true : false} toggle={() => api.setCreateCommentModal(false)}>
            {trade && <ModalHeader toggle={() => api.setCreateCommentModal(false)}>Create Comment on <a href={url(trade)} target="_blank" rel="noreferrer" className="no-link">{trade.exchange}:<ForexPair pair={trade.pair} /></a> <StatusBadge status={trade.status} /> <ResultBadge result={trade.result} /></ModalHeader>}
            <ModalBody>
                <FormGroup>
                    <Label>Snapshot url</Label>
                    <Input type="text" value={query.snapshot_url} onChange={(e) => setQuery((prev) => ({ ...prev, snapshot_url: e.target.value }))} />
                </FormGroup>
                <FormGroup>
                    <Label>Message</Label>
                    <Input type="text" value={query.message} onChange={(e) => setQuery(prev => ({ ...prev, message: e.target.value }))} />
                </FormGroup>
                <FormGroup>
                    <Label >Status</Label>
                    <div className="text-black">
                        <Select
                            options={STATUS}
                            value={STATUS.find(i => i.value === query.status)}
                            onChange={(value) => setQuery(prev => ({ ...prev, status: value.value }))}
                            placeholder="Status"
                        />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>Result (GOA)</Label>
                    <InputGroup>
                        <Input type="number" step="1" value={query.result} onChange={(e) => setQuery(prev => ({ ...prev, result: e.target.value }))} />
                        <InputGroupText>%</InputGroupText>
                    </InputGroup>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="success" disabled={api.isLoading} onClick={onSubmit}>Submit</Button>
                <Button color="light" disabled={api.isLoading} onClick={() => api.setCreateCommentModal(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

function UpdateCommmentModal() {
    const api = useContext(TradelogContext);

    const onSubmit = () => {
        api.updateComment(api.updateCommentModal);
        api.setUpdateCommentModal(false);
    }

    return (
        <Modal size="lg" isOpen={api.updateCommentModal} toggle={() => api.setUpdateCommentModal(false)}>
            <ModalHeader toggle={() => api.setUpdateCommentModal(false)}>Update Comment</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Snapshot url</Label>
                    <Input type="text" value={api.updateCommentModal.snapshot_url} onChange={(e) => api.setUpdateCommentModal(prev => ({ ...api.updateCommentModal, snapshot_url: e.target.value }))} />
                </FormGroup>
                <FormGroup>
                    <Label>Message</Label>
                    <Input type="text" value={api.updateCommentModal.message} onChange={(e) => api.setUpdateCommentModal(prev => ({ ...api.updateCommentModal, message: e.target.value }))} />
                </FormGroup>
                <FormGroup>
                    <Label >Status</Label>
                    <div className="text-black">
                        <Select
                            options={STATUS}
                            value={STATUS.find(i => i.value === api.updateCommentModal.status)}
                            onChange={(value) => api.setUpdateCommentModal(prev => ({ ...prev, status: value.value }))}
                            placeholder="Status"
                        />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>Result (GOA)</Label>
                    <InputGroup>
                        <Input type="number" step="1" value={api.updateCommentModal.result} onChange={(e) => api.setUpdateCommentModal(prev => ({ ...prev, result: e.target.value }))} />
                        <InputGroupText>%</InputGroupText>
                    </InputGroup>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="success" disabled={api.isLoading} onClick={onSubmit}>Submit</Button>
                <Button color="light" disabled={api.isLoading} onClick={() => api.setUpdateCommentModal(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}


export const SnapshotImg = ({ src }) => {
    return <div className="img-container"><img className="" alt="Snapshot" src={src} /></div>
}

function Graphs() {
    const api = useContext(TradelogContext);
    return <Container fluid={api.fullscreen} className="pt-5">
        <Row>
            <Col md="6">
                <SunburstChart />
                <TradeDurationChart />
            </Col>
            <Col md="6">
                <WaterfallChart />
                <OpenTradesChart />
            </Col>
        </Row>
    </Container>
}