import ReactEcharts from 'echarts-for-react';
import React from 'react';

const CandleChart = ({ candleData }) => {
  const getOption = () => {
    return {
      xAxis: {
        type: 'category',
        data: candleData.map((_, index) => index),  // Index as x-axis labels
        show: false  // Hide x-axis for a clean mini chart
      },
      yAxis: {
        type: 'value',
        show: false,  // Hide y-axis for a clean mini chart
        min: 'dataMin',  // Auto-fit to the minimum value in data
        max: 'dataMax',  // Auto-fit to the maximum value in data
      },
      series: [{
        type: 'candlestick',
        // echarts work with open close, low, high data
        data: candleData.map(ohlc => [ohlc[0], ohlc[3], ohlc[2], ohlc[1]]),  // Reorder OHLC data
        itemStyle: {
          color: '#FFFFFF',  // Bullish candle (close > open) fill color
          color0: '#B3B5BD',  // Bearish candle (open > close) fill color
          borderColor: '#FFFFFF',  // Bullish candle border color
          borderColor0: '#B3B5BD',  // Bearish candle border color
        }
      }],
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      tooltip: {
        show: false  // Disable tooltip for mini chart
      },
      legend: {
        show: false  // Hide legend
      }
    };
  };

  return (
    <ReactEcharts
      option={getOption()}
      style={{ height: '200px', width: '100%' }}  // Set height and width for the chart
    />
  );
};

export default CandleChart;
