import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { useContext } from 'react';
import { TradelogContext } from './context';

const calculateTradeDurations = (trades) => {
    return trades.map(trade => {
        const openDate = dayjs(trade.open_date);
        const closedDate = dayjs(trade.closed_date);
        const duration = closedDate.diff(openDate, 'day');  // Calculate duration in days
        return {...trade, duration};
    });
};

const calculateMean = (durations) => {
    const sum = durations.reduce((acc, duration) => acc + duration, 0);
    return sum / durations.length;
};

const calculateMedian = (durations) => {
     // First, check if the data is valid and has elements
     if (!durations || durations.length === 0) return 0;  // Handle case with no data
    const sortedDurations = [...durations].sort((a, b) => a - b);
    const mid = Math.floor(sortedDurations.length / 2);
    if (sortedDurations.length % 2 === 0) {
        return (sortedDurations[mid - 1] + sortedDurations[mid]) / 2;
    }
    return sortedDurations[mid];
};
const TradeDurationChart = () => {
    const api = useContext(TradelogContext);
    if (!api.filteredTrades) return null;

    // Step 1: Calculate the trade durations
    const tradeDurations = calculateTradeDurations(api.filteredTrades.filter(i => !["cancelled", "waiting", "unfilled"].includes(i.status)));

    // Step 2: Sort the durations
    const sortedDurations = [...tradeDurations].sort((a, b) => a.duration - b.duration);
    const durations = sortedDurations.map(i => i.duration);

    // Step 3: Calculate the average duration
    const averageDuration = calculateMean(durations);
    const medianDuration = calculateMedian(durations);

    // Step 4: Set up the chart options for ECharts
    const option = {
        xAxis: {
            type: 'category',
            data: sortedDurations.map((_, index) => `Trade ${index + 1}`),  // Label each trade by its position in the sorted array
            axisLabel: {
                formatter: '{value}'
            },
            name: 'Trades (sorted by duration)',
            show: false,  // Hide the x-axis
        },
        yAxis: {
            type: 'value',
            name: 'Duration (days)',
            axisLabel: {
                formatter: '{value} days'
            },
            show: false,  // Hide the y-axis
        },
        series: [
            {
                type: 'bar',
                name: 'Duration',
                data: durations,
                itemStyle: {
                    color: '#00ffdb'
                },
                tooltip: {
                    trigger: 'axis',
                    valueFormatter: function (value) {
                        return `${value.toFixed(2)} days`;
                    }
                },

            },
            {
                type: 'line',
                name: "Average",
                showSymbol: false,   // Remove in-between dots (markers) at datapoints
                data: sortedDurations.map(() => averageDuration),
                itemStyle: {
                    color: 'pink'
                },

            },
            {
                type: 'line',
                name: "Median",
                showSymbol: false,   // Remove in-between dots (markers) at datapoints
                data: sortedDurations.map(() => medianDuration),
                itemStyle: {
                    color: 'purple'
                },
            },
        ],
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                const point = sortedDurations[params[0].dataIndex]
                let tooltipContentArray = [
                    `<b>${point.pair}</b><br/>`,  // Show epic
                    `Open date: <b>${point.open_date}</b><br/>`,  // Show open date
                    `Closed on: <b>${point.closed_date}</b><br/><br/>`,  // Show close date
                ];

                params.forEach(item => {
                    tooltipContentArray.push(
                        `${item.marker} ${item.seriesName}: <b>${item.data.value !== undefined ? item.data.value.toFixed(2) : item.data.toFixed(2)} days</b><br/>`
                    );
                });

                return tooltipContentArray.join('');
            }
        },

    };


    return <ReactECharts option={option} style={{ height: '300px', width: '100%' }} />;
};


export default TradeDurationChart;
