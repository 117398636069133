import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import React, { useContext } from 'react';
import { TradelogContext } from './context';

const prepareOpenTradesData = (trades) => {
    const events = trades.flatMap(trade => [
        { ...trade, date: dayjs(trade.open_date), change: +1 },
        { ...trade, date: dayjs(trade.closed_date), change: -1 }
    ]);

    events.sort((a, b) => a.date.diff(b.date));

    let openTrades = 0;
    const openTradesOverTime = events.map(event => {
        openTrades += event.change;
        return {
            ...event,
            date: event.date.format('D MMM YYYY'),
            openTrades
        };
    });

    return openTradesOverTime;
};

// Calculate the average number of open trades
const calculateAverage = (data) => {
    const totalTrades = data.reduce((acc, point) => acc + point.openTrades, 0);
    return totalTrades / data.length;
};

const calculateMedian = (data) => {
    // First, check if the data is valid and has elements
    if (!data || data.length === 0) return 0;  // Handle case with no data

    // Sort the data based on 'openTrades'
    const sortedData = [...data].sort((a, b) => a.openTrades - b.openTrades);

    const mid = Math.floor(sortedData.length / 2);

    // If the array length is even, take the average of the two middle values
    if (sortedData.length % 2 === 0) {
        return (sortedData[mid - 1].openTrades + sortedData[mid].openTrades) / 2;
    }

    // Otherwise, return the middle value
    return sortedData[mid].openTrades;
};


export const  getMarker = (color) => `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`;

const OpenTradesChart = () => {
    const api = useContext(TradelogContext);
    if (!api.filteredTrades) return null;

    const openTradesData = prepareOpenTradesData(api.filteredTrades.filter(i => !["cancelled", "waiting", "unfilled", "filled"].includes(i.status)));
    const averageOpenTrades = calculateAverage(openTradesData);  // Calculate the average
    const medianOpenTrades = calculateMedian(openTradesData);  // Calculate the median

    const option = {
        xAxis: {
            type: 'category',
            data: openTradesData.map(d => d.date),
            show: false,  // Hide the x-axis
        },
        yAxis: {
            type: 'value',
            show: false,  // Hide the y-axis
        },
        series: [
            {
                type: 'bar',
                name: 'Open Trades',
                data: openTradesData.map(d => d.openTrades),
                label: {
                    show: false,
                },
                lineStyle: {
                    color: '#00ffdb'
                },
                itemStyle: {
                    color: '#00ffdb'
                },
                tooltip: {
                    valueFormatter: function (value) {
                        return `${value} trades<br</br>Avg: ${averageOpenTrades.toFixed(2)}`;
                    }
                },
            },
            {
                type: 'line',
                name: "Average",
                showSymbol: false,   // Remove in-between dots (markers) at datapoints
                data: openTradesData.map(() => averageOpenTrades),
                itemStyle: {
                    color: 'pink'
                },

            },
            {
                type: 'line',
                name: "Median",
                showSymbol: false,   // Remove in-between dots (markers) at datapoints
                data: openTradesData.map(() => medianOpenTrades),
                itemStyle: {
                    color: 'purple'
                },
            },
        ],
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                const point = openTradesData[params[0].dataIndex];
                let tooltipContentArray = [
                    `<b>${point.pair}</b><br/>`,  // Show epic
                    `Open date: <b>${point.open_date}</b><br/>`,  // Show open date
                    `Closed on: <b>${point.closed_date}</b><br/><br/>`,  // Show close date
                ];

                params.forEach(item => {
                    tooltipContentArray.push(
                        `${item.marker} ${item.seriesName}: <b>${item.data.value !== undefined ? item.data.value.toFixed(2) : item.data.toFixed(2)}</b> trades<br/>`
                    );
                });

                return tooltipContentArray.join('');
            }
        },
    };

    return <ReactECharts option={option} style={{ height: '300px', width: '100%' }} />;
};

export default OpenTradesChart;