import ReactEcharts from 'echarts-for-react';
import React from 'react';

const PriceChart = ({ prices, sma }) => {
  const getOption = () => {
    return {
      xAxis: {
        type: 'category',
        data: prices.map((_, index) => index),  // Use index as the x-axis labels
        show: false  // Hide x-axis
      },
      yAxis: {
        type: 'value',
        show: false,  // Hide y-axis,
        min: 'dataMin',  // Auto-fit to the minimum value in data
        max: 'dataMax',  // Auto-fit to the maximum value in data
      },
      series: [
        {
        data: prices,
        type: 'line',
        lineStyle: {
          color: '#ffffff',  // White line
          width: 2
        },
        symbol: 'none',  // Remove the marker symbols (circles)
        showSymbol: false,  // Also hide any symbols if they were set elsewhere
      },
      {
        data: sma,
        type: 'line',
        lineStyle: {
          color: '#eee',  // White line
          width: 2
        },
        symbol: 'none',  // Remove the marker symbols (circles)
        showSymbol: false,  // Also hide any symbols if they were set elsewhere
      }
    ],
      tooltip: {
        show: false  // Disable tooltip
      },
      legend: {
        show: false  // Hide legend
      },
      grid: {
        left: 0,  // Adjust grid to remove any margins
        right: 0,
        top: 0,
        bottom: 0
      }
    };
  };

  return (
    <ReactEcharts
      option={getOption()}
      style={{ height: '520px', width: '100%' }}  // Set height and width as needed
    />
  );
};

export default PriceChart;
